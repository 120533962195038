import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';

import { AuthManager, AuthNavigator } from '../interfaces';
import { AUTH_MANAGER, AUTH_NAVIGATOR } from '../tokens';

export const notAuthCanActivateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authNavigator: AuthNavigator = inject(AUTH_NAVIGATOR);
    const authManager: AuthManager = inject(AUTH_MANAGER);
    return authManager.isAuthenticated().pipe(map((isAuthenticated: boolean) => !isAuthenticated || authNavigator.loginUrlTree()));
};

import { FactoryProvider } from '@angular/core';
import { Router } from '@angular/router';

import { TENANT_ID_TOKEN } from '../tokens';
import { TldUtils } from '../utils';

export function provideTenantId(): FactoryProvider {
    return {
        provide: TENANT_ID_TOKEN,
        deps: [Router],
        useFactory: (router: Router): string | null => TldUtils.getSubdomain(),
    };
}

import { HttpClient, HttpContextToken } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { API_URL_HTTP_CONTEXT_TOKEN } from '../../tokens';
import { WithHttpClientContexts } from '../../utils';

@WithHttpClientContexts({ name: 'tokens' })
@Injectable({ providedIn: 'root' })
export class ApiHttpClient extends HttpClient {
    public readonly tokens: Array<HttpContextToken<boolean>> = [inject(API_URL_HTTP_CONTEXT_TOKEN)];
}

export namespace LocalStorageUtil {
    export function getItem<T>(key: string): T | null {
        const item: string | null = localStorage.getItem(key);
        if (typeof item === 'string') {
            try {
                return JSON.parse(item) as T;
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    export function setItem<T = any>(key: string, value: T): void {
        let parsedValue: string;
        try {
            parsedValue = JSON.stringify(value);
        } catch (e) {
            parsedValue = 'null';
        }

        try {
            localStorage.setItem(key, parsedValue);
        } catch (e) {
            // QuotaExceededError: DOMException
            localStorage.setItem(key, 'null');
        }
    }

    export function hasItem(key: string): boolean {
        return localStorage.hasOwnProperty(key);
    }

    export function removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    export function onKey<T>(key: string, onHasKey: (value: T | null) => void, onHasNotKey?: () => void): void {
        hasItem(key) ? onHasKey(getItem<T>(key)) : onHasNotKey?.();
    }

    export function registerStorageEventListener(windowRef: Window, storageEventListener: (evt: StorageEvent) => void): void {
        windowRef.addEventListener('storage', storageEventListener, false);
    }

    export function unregisterStorageEventListener(windowRef: Window, storageEventListener: (evt: StorageEvent) => void): void {
        windowRef.removeEventListener('storage', storageEventListener);
    }
}

import { InjectionToken } from '@angular/core';

import { useIsDevelopment, useIsProduction, useIsStage } from '../contexts';

export enum ApplicationFeatures {
    CALENDAR_APPLICATION = 'CALENDAR_APPLICATION',
    CASE_FILE_APPLICATION = 'CASE_FILE_APPLICATION',
    CONTACTS_APPLICATION = 'CONTACTS_APPLICATION',
    ACCOUNTING_APPLICATION = 'ACCOUNTING_APPLICATION',
    CHARTING_APPLICATION = 'CHARTING_APPLICATION',
    TEAM_APPLICATION = 'TEAM_APPLICATION',
    ADMIN_CONSOLE_APPLICATION = 'ADMIN_CONSOLE_APPLICATION',
    ACCOUNT_PROFILE_MODAL = 'ACCOUNT_PROFILE_MODAL',
    DEVELOPMENT_APPLICATION = 'DEVELOPMENT_APPLICATION',
    USER_APPLICATION = 'USER_APPLICATION',
}

export type ApplicationFeaturesAvailability = { [feature in ApplicationFeatures]: boolean };

export const ApplicationFeaturesAvailabilityToken = new InjectionToken<ApplicationFeaturesAvailability>(
    `Describes any application Feature that has different behaviour in development, stage or production`,
    {
        providedIn: 'root',
        factory: () => {
            const isDevelopment = useIsDevelopment();
            const isStage = useIsStage();
            const isProduction = useIsProduction();

            return {
                [ApplicationFeatures.CALENDAR_APPLICATION]: isDevelopment,
                [ApplicationFeatures.CASE_FILE_APPLICATION]: isDevelopment || isStage,
                [ApplicationFeatures.CONTACTS_APPLICATION]: isDevelopment || isStage || isProduction,
                [ApplicationFeatures.ACCOUNTING_APPLICATION]: isDevelopment,
                [ApplicationFeatures.CHARTING_APPLICATION]: isDevelopment,
                [ApplicationFeatures.TEAM_APPLICATION]: isDevelopment || isStage,
                [ApplicationFeatures.ADMIN_CONSOLE_APPLICATION]: isDevelopment || isStage,
                [ApplicationFeatures.ACCOUNT_PROFILE_MODAL]: isDevelopment || isStage,
                [ApplicationFeatures.DEVELOPMENT_APPLICATION]: isDevelopment,
                [ApplicationFeatures.USER_APPLICATION]: isDevelopment || isStage || isProduction,
            };
        },
    }
);

import { HttpContextToken, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { API_URL_HTTP_CONTEXT_TOKEN, API_URL_TOKEN } from '../tokens';

export const apiUrlInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const httpContextToken: HttpContextToken<boolean> = inject(API_URL_HTTP_CONTEXT_TOKEN);

    if (!req.context.has(httpContextToken) || !req.context.get<boolean>(httpContextToken)) {
        return next(req);
    }

    const apiUrl: string = inject(API_URL_TOKEN);

    return next(
        req.clone({
            url: `${apiUrl}/${req.url}`,
        })
    );
};

import { inject, Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

import { WINDOW } from '../providers';
import { LocalStorageUtil } from '../utils';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    private readonly windowRef: Window | null = inject(WINDOW, { optional: true });
    private readonly ngZone: NgZone = inject(NgZone);

    public receive<T>(key: string): Observable<T | null> {
        let storageEventListener: ((evt: StorageEvent) => void) | null;

        return new Observable<T | null>((subscriber: Subscriber<T | null>) => {
            this.ngZone.runOutsideAngular(() => {
                storageEventListener = (evt: StorageEvent) => {
                    if (evt.key === key) {
                        this.ngZone.run(() => {
                            subscriber.next(LocalStorageUtil.getItem(key));
                        });
                    }
                };

                if (this.windowRef != null) {
                    LocalStorageUtil.registerStorageEventListener(this.windowRef, storageEventListener);
                }
            });

            return () => {
                subscriber.complete();
                if (this.windowRef != null && storageEventListener != null) {
                    LocalStorageUtil.unregisterStorageEventListener(this.windowRef, storageEventListener);
                }
            };
        });
    }
}

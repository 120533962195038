import { HttpContext, HttpContextToken } from '@angular/common/http';

/* eslint-disable */
export function WithHttpClientContexts({name}: {name: string}) {
    return function (constructor: Function) {
        const originalDeleteMethod = constructor.prototype.delete;
        const originalGetMethod = constructor.prototype.get;
        const originalHeadMethod = constructor.prototype.head;
        const originalOptionsMethod = constructor.prototype.options;
        const originalPatchMethod = constructor.prototype.patch;
        const originalPostMethod = constructor.prototype.post;
        const originalPutMethod = constructor.prototype.put;

        constructor.prototype.delete = function (url: string, options: any = {}) {
            return originalDeleteMethod.call(this, url, getOptionsContext(this[name], options));
        };
        constructor.prototype.get = function (url: string, options: any = {}) {
            return originalGetMethod.call(this, url, getOptionsContext(this[name], options));
        };
        constructor.prototype.head = function (url: string, options: any = {}) {
            return originalHeadMethod.call(this, url, getOptionsContext(this[name], options));
        };
        constructor.prototype.options = function (url: string, options: any = {}) {
            return originalOptionsMethod.call(this, url, getOptionsContext(this[name], options));
        };
        constructor.prototype.patch = function (url: string, body: any | null, options: any = {}) {
            return originalPatchMethod.call(this, url, getOptionsContext(this[name], options));
        };
        constructor.prototype.post = function (url: string, body: any | null, options: any = {}) {
            return originalPostMethod.call(this, url, body, getOptionsContext(this[name], options));
        };
        constructor.prototype.put = function (url: string, body: any | null, options: any = {}) {
            return originalPutMethod.call(this, url, body, getOptionsContext(this[name], options));
        };

        function getOptionsContext(apiHttpContextTokens: Array<HttpContextToken<boolean>>, options: any): any {
            const context: HttpContext = options.hasOwnProperty('context') ? (options['context'] as HttpContext) : new HttpContext();
            apiHttpContextTokens.forEach((apiHttpContextToken) => {
                if (!context.has(apiHttpContextToken)) {
                    context.set(apiHttpContextToken, true);
                }
            });
            return { ...options, context };
        }
    };
}
/* eslint-enable */
